<template>
    <div class="company-contain">
        <div class="head_box">
            <!--筛选条件-->
            <div class="filter-box">
                <div class="box1">
                    <span>任务分类：</span>
                    <div class="filter-box1">
                        <span @click="allClas" :class="{activeClass:isAll == 0}">全部</span>
                        <span @click="isAllS(item)"
                              :class="{activeClass:isAll == item.id}"
                              v-for="item in typeLsit"
                              :key="item.id"
                        >{{item.name}}</span>
                    </div>
                </div>
            </div>
            <div class="divide">
                <el-divider></el-divider>
            </div>
            <div class="time-box" style="padding-bottom: 20px;padding-left: 20px;">
                <el-radio-group class="radio-group" v-model="radio" size="small" @change="getListByTime">
                    <el-radio-button label="0">日榜</el-radio-button>
                    <el-radio-button label="1">周榜</el-radio-button>
                    <el-radio-button label="2">月榜</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div style="background-color: #F3F3F6;height: 20px;width: 100%"></div>
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%;overflow: hidden;min-height:560px"
                    size="medium"
                    :header-cell-style="{fontWeight: 'Regular', color: '#333', background: '#fff',}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="task_id"
                        label="视频内容"
                        align="left"
                        width="700"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <div @click="changeToDetail(scope.row)" class="videoBox" style="display: flex;cursor: pointer;">
                            <div class="picBox">
                                <img style="width: 90px;height: 120px;" :src=scope.row.video_cover>
                            </div>
                            <div style="padding: 20px 0 10px 20px;">
                                <div style="font-size:14px;">{{scope.row.video_title}}</div>
                                <div>
                                    <span>热词：</span>
                                    <span v-if="scope.row.video_tags == 0">无</span>
                                    <span v-else style="margin-left: 5px;"
                                          v-for="item in scope.row.video_tags.split(',')" :key="item">{{item}}</span>
                                </div>
                                <div>
                                    <span>发布时间：</span>
                                    <span>{{scope.row.time}}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="video_author" width="200" label="播主" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <p class="student-name-title">{{scope.row.video_author}}</p>
                        <p class="student-name-title">{{scope.row.release_time}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_likes" label="点赞总数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.video_total_likes}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_comments" label="评论总数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.video_total_comments}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_forwarding" label="转发总数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.video_total_forwarding}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" circle @click="changeToDetail(scope.row)" size="small">
                            <i class="iconfont">&#xe6a1;</i>
                        </el-button>
                        <el-button type="success" circle size="small" @click="jumpToFansData(scope.row)">
                            <i class="iconfont">&#xe647;</i>
                        </el-button>
                        <el-button type="danger" circle @click="jumpToVideo(scope.row)" size="small">
                            <i class="iconfont">&#xe63a;</i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="pages-center"
                style="padding: 10px;"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        name: 'HotVideo',
        data() {
            return {
                isAll: 0,
                radio: "0",
                video_class_name: "",//分类名称
                typeLsit: [], //分类列表
                task_condition: "",
                taskList: [],//任务列表
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //编辑获得的数据
                editForm: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是
                    file: [] //附件
                }
            }
        },
        mounted() {
            this.getTaskType();
            this.getAllClass();
        },
        methods: {
            isAllS(item) {
                this.isAll = item.id;
                this.video_class_name = item.name;
            },
            //全部
            allClas() {
                this.isAll = 0;
                this.video_class_name = "";
            },
            getTaskType() {
                let params = {};
                this.$httpStudent.axiosGetBy(this.$api.getHotVideoClassList, params, res => {
                    this.typeLsit = res.list;
                });
            },
            getListByTime() {
                this.getAllClass();
            },
            get1() {

            },
            // 获取全部视频列表
            getAllClass() {
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum, //页面显示条数
                    type: this.radio,
                    video_class_name: this.video_class_name,//任务类型
                };
                if (this.video_class_name == "") {
                    delete param.video_class_name
                }
                if (this.radio == "") {
                    delete param.type
                }
                this.$httpStudent.axiosGetBy(this.$api.getHotVideoList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.list;
                        this.adminPages.total = res.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAllClass();
            },
            //跳到详情页
            changeToDetail(row) {
                this.$router.push({
                    path: "/student/hot/videoDetail",
                    query: {
                        videoId: row.id,
                        activename:"first"
                    }
                })
            },
            //跳到粉丝详情
            jumpToFansData(row) {
                this.$router.push({
                    path: "/student/hot/videoDetail",
                    query: {
                        videoId: row.id,
                        activename:"second"
                    }
                })
            },
            //打开视频
            jumpToVideo(row){
                window.open(row.video_url);
            },
            // 编辑
            editCompany(row) {
                let param = {
                    'task_id': row.task_id
                }
                this.$httpcompany.axiosGetBy(this.$api.taskEdit, param, (res) => {
                    if (res.code == 200) {
                        const resInfo = res.info
                        this.editForm.id = resInfo.task_id;
                        if (resInfo.task_type_id == 6) {
                            this.editForm.goods_id = resInfo.goods_id;
                        }
                        this.editForm.tasktype = resInfo.task_type_id;
                        this.editForm.title = resInfo.task_title;
                        this.editForm.describe = resInfo.task_content;
                        this.editForm.cover = resInfo.task_cover_file;
                        this.editForm.time = resInfo.task_end_time;
                        this.editForm.Islimit = resInfo.task_is_limit;
                        this.editForm.limitnum = resInfo.task_limit_num;
                        this.editForm.taskmoney = resInfo.task_money;
                        this.editForm.apply = resInfo.task_is_apply;
                        if (resInfo.task_file.length) {
                            for (let i = 0; i < resInfo.task_file.length; i++) {
                                delete resInfo.task_file[i].id;
                                delete resInfo.task_file[i].task_id;
                            }
                            this.editForm.file = resInfo.task_file;
                            this.editForm.file = JSON.parse(JSON.stringify(this.editForm.file).replace(/file_url/g, "file_path"))
                        }
                        this.$router.push({
                            path: "/company/taskcenter/publish",
                            query: {editForm: JSON.stringify(this.editForm)}
                        })
                    }
                })
            },
            // 删除
            delCompany(row) {
                let that = this;
                const id = row.task_id;
                if (row.task_state == "进行中") {
                    this.$message({
                        message: '任务进行中无法删除',
                        type: 'warning'
                    });
                    return
                } else {
                    let param = {
                        task_id: id
                    };
                    this.$confirm("删除系统任务后不能恢复，确定要删除吗？", "删除任务", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        that.$httpcompany.axiosGetBy(that.$api.delTask, param, res => {
                            console.log(res);
                            if (res.code == 200) {
                                that.$message({
                                    type: "success",
                                    message: "删除成功"
                                });
                                that.getAllClass();
                            } else {
                                that.$message.warning(res.msg);
                            }
                        });
                    });
                }
            },
            //发布任务
            publishTask() {
                this.$router.push({path: "/company/taskcenter/publish"})
            }
        },
        watch: {
            isAll: function (val) {
                this.getAllClass();
            },
        }

    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #2338E6;
        color: #ffffff;
    }

    .company-contain {
        background-color: #ffffff;

        .divide {
            padding: 0 20px;
        }

        .filter-box {
            padding-top: 10px;

            .box1 {
                padding: 0 20px;
                display: flex;
                line-height: 40px;

                .filter-box1 {
                    flex: 1;
                    span {
                        margin-top: 6px;
                        line-height: 32px;
                        display: inline-block;
                        width: 66px;
                        height: 32px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .time-box {
            .radio-group {
                ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                    background-color: #2338E6;
                    border-color: #2338E6;
                }

                ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
                    color: #fff;
                }

                ::v-deep .el-radio-button__inner:hover {
                    color: #2338E6;
                }
            }
        }
    }
</style>
